import React from "react";
import {Link} from "gatsby";
import Header from "../components/header";
import Footer from "../components/footer";
import SEO from "../components/seo";
import MessengerChat from "../components/MessengerChat"
//import {Button} from "react-bootstrap";
import Button from "../components/button";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

//style={{position:"relative", left:"80px", top:"150px", backgroundColor:"white", color:"#E62C58", padding:"20px", borderStyle:"outset"}}

// {/*
//  <h5>Та мобайл аппликейшн хийлгэх бодолтой байна уу. Эсвэл маш хурдтай мобайл веб сайт ?</h5>
//  <h3>Бид хамгийн сайныг нь хийдэг!</h3>
//  */}

const IndexPage = () => {
    const siteTitle = "iNext"

    return (
        <>
        <Header siteTitle={siteTitle}/>
        <SEO
            title="Home"
            keywords={[`mongolia`, `mobile`, `javascript`, `web`, `ios`, `android`, `inext`]}
        />
        <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
                <div className="col-12">
                    <div style={{ backgroundColor: "#efefef", padding: "20px", marginTop: "80px"}}>

                        <h1 style={{textAlign: "center"}}>
                            Hey people{" "}
                            <span role="img" aria-label="wave emoji">
            👋
          </span>
                        </h1>
                        <br/><br/><br/><br/>

                        <h5 style={{textAlign: "center"}}>In need of native iPhone and Android
                            applications? Or do you need a fast mobile website? </h5>
                        <br/><br/><br/><br/>
                        <h3 style={{textAlign: "center"}}>We build something great!</h3>
                        <br/>

<p>
                        <Link marginTop="35px" to="/contact/">
                            <Button background="#E62C58">Contact us</Button>
                        </Link>
</p>
                    </div>

                </div>
            </div>
        </div>
        <MessengerChat />
        <Footer/>
        </>

    )
}

export default IndexPage
