/**
 * Created by gantushig on 5/21/20.
 */

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import _ from "lodash"
import "bootstrap/dist/css/bootstrap.min.css"
import "./header.scss";
import mng from "./mongolia.png"


const Footer = () => {
    return (
        <footer className="navbar fixed-bottom bg-light">
            <div className="container">
                © {new Date().getFullYear()}, iNext - Quality Mobile and Web App Developer.
            </div>
        </footer>
    )
}

export default Footer